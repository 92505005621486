/* constant */

:root {
  --primary-50: #EBFDFF;
  --primary-100: #CFF8FD;
  --primary-200: #A4F2FB;
  --primary-300: #66E7F8;
  --primary-400: #22D1EC;
  --primary-500: #05B5D3;
  --primary-600: #0790B0;
  --primary-700: #0D738F;
  --primary-800: #145D73;
  --primary-900: #154D62;

  --secondary-50: #EDF1FE;
  --secondary-100: #DFE6FE;
  --secondary-200: #C6D1FD;
  --secondary-300: #A4B3FA;
  --secondary-400: #808BF6;
  --secondary-500: #6265F0;
  --secondary-600: #4E44E4;
  --secondary-700: #4237C8;
  --secondary-800: #363FA2;
  --secondary-900: #302C80;

  --tertiary-50: #FEFBE7;
  --tertiary-100: #FEFBE7;
  --tertiary-200: #FDF088;
  --tertiary-300: #FCD047;
  --tertiary-400: #F8CB15;
  --tertiary-500: #E9B207;
  --tertiary-600: #C98804;
  --tertiary-700: #A06206;
  --tertiary-800: #844D0E;
  --tertiary-900: #703E11;

  --grey-50: #F7FAFB;
  --grey-100: #F0F4F8;
  --grey-200: #E1E7EF;
  --grey-300: #CBD4DF;
  --grey-400: #93A2B7;
  --grey-500: #63638A;
  --grey-600: #465368;
  --grey-700: #334055;
  --grey-800: #1D283A;
  --grey-900: #0E1629;

  --green-50: #EBFDF4;
  --green-100: #D0F9E3;
  --green-200: #A6F2CF;
  --green-300: #6CE7B6;
  --green-400: #33D198;
  --green-500: #0FB87F;
  --green-600: #058665;
  --green-700: #037655;
  --green-800: #065D45;
  --green-900: #054D3A;

  --red-50: #FDF1F1;
  --red-100: #FDDDDD;
  --red-200: #FEB9B9;
  --red-300: #FB9494;
  --red-400: #F67171;
  --red-500: #EE4242;
  --red-600: #D82323;
  --red-700: #B71A1A;
  --red-800: #951A1A;
  --red-900: #7D1C1C;

  --black: #030617;
  --white: #FBFEFE;

  --dimmer-color: rgb(1 3 8 / 80%);
  
  --title-font: 'Kumbh Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;;
  --primary-font: 'Source Sans 3', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;

  --shadow-200: 0px 4px 16px #64748B0e;
  --shadow-400: 0px 2px 12px #64748b40;
}

/* css */

body {
  height: fit-content;
  font-family: var(--primary-font);
  color: var(--grey-700);
  font-size: 16px;
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--title-font);
  margin: 0;
}

h1 {
  font-size: 48px;
  line-height: 1;
  font-weight: 700;
}

h2 {
  font-size: 32px;
  line-height: 1.125;
  font-weight: 700;
}

h3 {
  font-size: 24px;
  line-height: 1.5;
  font-weight: 700;
}

h4 {
  font-size: 20px;
  line-height: 1.5;
  font-weight: 700;
}

h5 {
  font-size: 18px;
  line-height: 1.5;
  font-weight: 500;
}

h6 {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 700;
}

p, span {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
}

a {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  color: var(--secondary-500);
  text-decoration: underline;
}

a:hover {
  color: var(--secondary-700);
  text-decoration: underline;
}



.ls-split{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ui.button.ls-split{
  display: flex;
    align-items: center;
    justify-content: space-between;
}

.ls-split.ls-split > *{
  margin: 0;
}

.ui.card>.content {
  padding: 2em;
}


.ui.loader:before {
  border: 0.2em solid #4abda737;
}
.ui.loader:after {
  border-color: #4abda7 transparent transparent;
}

button, .ui.button {
  font-family: var(--title-font);
  border: none;
  background: var(--grey-500);
  color: var(--white);
  font-size: 16px;
  margin: 0;
  border-radius: 4px;
  padding: 8px 20px;
  line-height: 1.5;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.ui.button:hover, .ui.button:focus {
  background: var(--grey-700);
}

.ui.button.primary {
  background: var(--primary-500);
}

.ui.button.primary:hover, .ui.button.primary:focus {
  background: var(--primary-700);
}

.ui.button.secondary {
  background: var(--secondary-500);
}
.ui.button.secondary:hover, .ui.button.secondary:focus {
  background: var(--secondary-700);
}

.ui.button.positive {
  background: var(--green-500);
}
.ui.button.positive:hover, .ui.button.positive:focus {
  background: var(--green-700);
}

.ui.button.negative {
  background: var(--red-500);
}
.ui.button.negative:hover, .ui.button.negative:focus {
  background: var(--red-700);
}

.ui.button.grey {
  background: var(--grey-500);
}
.ui.button.grey:hover, .ui.button.grey:focus {
  background: var(--grey-700);
}

.ui.button.ls-icon.ls-icon.ls-icon {
  height: 40px;
  width: 40px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ui.button.ls-with-icon {
  display: flex;
  align-items: center;
  margin-left: auto;
  padding-right: 10px;

  :not(.basic) svg {
    fill: white;
  }
}

.ui.button.ls-as-text {
  background: transparent;
}

.ui.button.ls-as-text:hover {
  background: transparent;
}

.ui.button.ls-as-text.ui.secondary {
  color: var(--secondary-600);
  svg {
    fill: var(--secondary-600);
  }
}

.ui.button.ls-as-text.ui.secondary:hover {
  color: var(--secondary-800);
  svg {
    fill: var(--secondary-800);
  }
}

.ui.button.ls-bright.primary {
    padding: 7px 16px;
  background: var(--primary-50);
      border: 1px solid var(--primary-200);
      color: var(--primary-700);
      fill: var(--primary-700);
      font-weight: 500;
}

.ui.button.ls-bright.primary:hover {
  background: var(--primary-100);
      border: 1px solid var(--primary-300);
      color: var(--primary-800);
      fill: var(--primary-800);
      font-weight: 500;
}

.ui.button.ls-bright.secondary {
    padding: 7px 16px;
  background: var(--secondary-50);
      border: 1px solid var(--secondary-200);
      color: var(--secondary-700);
      fill: var(--secondary-700);
      font-weight: 500;

      svg {
        
      fill: var(--secondary-700);
      }
}

.ui.button.ls-bright.secondary:hover {
  background: var(--secondary-100);
      border: 1px solid var(--secondary-300);
      color: var(--secondary-800);
      fill: var(--secondary-800);
      font-weight: 500;
      
      svg {
        
      fill: var(--secondary-800);
      }
}

.ui.button.ls-bright.grey {
    padding: 7px 16px;
  background: var(--grey-50);
      border: 1px solid var(--grey-200);
      color: var(--grey-700);
      fill: var(--grey-700);
      font-weight: 500;
}

.ui.button.ls-bright.grey:hover {
  background: var(--grey-100);
      border: 1px solid var(--grey-300);
      color: var(--grey-800);
      fill: var(--grey-800);
      font-weight: 500;
}

.ui.button.ls-bright.red {
    padding: 7px 16px;
  background: var(--red-50);
      border: 1px solid var(--red-200);
      color: var(--red-700);
      fill: var(--red-700);
      font-weight: 500;
}

.ui.button.ls-bright.red:hover {
  background: var(--red-100);
      border: 1px solid var(--red-300);
      color: var(--red-800);
      fill: var(--red-800);
      font-weight: 500;
}

.ui.button.ls-bright.green {
    padding: 7px 16px;
  background: var(--green-50);
      border: 1px solid var(--green-200);
      color: var(--green-700);
      fill: var(--green-700);
      font-weight: 500;
}

.ui.button.ls-bright.green:hover {
  background: var(--green-100);
      border: 1px solid var(--green-300);
      color: var(--green-800);
      fill: var(--green-800);
      font-weight: 500;
}

.ui.button.ls-bright.compact {
  padding: 8px 12px;
  line-height: 1;
  font-family: var(--text-primary);
}

.ui.button.basic.primary {
    padding: 7px 16px;
    margin: 0;
    box-shadow: none !important;
    border: 1px solid var(--primary-600);
    color: var(--primary-600) !important;
    fill: var(--primary-600);
    border-radius: 4px;
}

.ui.button.basic.primary:hover {
    background: var(--primary-600) !important;
    box-shadow: none !important;
    border: 1px solid var(--primary-600);
    color: var(--white) !important;
    fill: var(--white);
}

button:hover, .ui.button:hover {
  background: var(--primary-light);
  color: var(--white)
}

button.ls-as-link, button.ls-as-link:hover, button.ls-as-link:focus {
  background: transparent;
  padding: 0px;
}

.ui.toggle.checkbox input:checked~.box:before, .ui.toggle.checkbox input:checked~label:before,
.ui.toggle.checkbox input:focus:checked~.box:before, .ui.toggle.checkbox input:focus:checked~label:before {
  background-color: var(--primary-500) !important;
}

.ui.modal {
  border-radius: 8px;
}

.ui.modal>:first-child:not(.icon) {
  border-radius: 8px 8px 0 0 ;
}


.ui.modal>:last-child:not(.icon) {
  border-radius: 0 0 8px 8px;
}

.ui.modal .header {
  border: none;
}

.ui.modal .header+.content {
  padding-top: 0;
  margin-top: -8px;
}

.ui.modal .actions  {
  display: flex;
  gap: 16px;
  align-items: flex-end;
  justify-content: center;
}

.ui.modal .content .actions  {
  margin-top: 20px;
  padding: 0;
}


.ui.button.basic {
  font-weight: 700;
  box-shadow: none;
  background-color: transparent !important;
  padding-inline-end: 8px;
}

.ui.button.basic:hover, .ui.button.basic:active, .ui.button.basic:focus, .ui.button.basic:focus-visible, .ui.button.basic:focus-within {
  box-shadow: none;
  background-color: transparent !important;
}

.ui.modal .content ul {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-inline-start: 0;
}

.ui.modal .content ul li{
  display: flex;
  flex-direction: column;
  gap: 8px;
}


.ui.visible.sidebar {
  background: white;
}

.ui.form, .ui.checkbox label {
  font-size: 16px;
}

.ui.tiny.modal {
        width: min(460px, calc(100vw - 48px)) !important;
        margin: 0;
    }

.ui.dropdown .ui.label {
  text-decoration: none;
    text-transform: none;
    background: var(--primary-50);
    border-color: var(--primary-200);
    color: var(--primary-800);
    box-shadow: 0 0 0 1px var(--primary-200);
}

.ui.dropdown>.dropdown.icon:before {
    display: block;
    height: 24px;
    width: 24px;
    margin: -2px -2px 0 0;
    content: '';
    --clear-icon:url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="M480-361q-8 0-15-2.5t-13-8.5L268-556q-11-11-11-28t11-28q11-11 28-11t28 11l156 156 156-156q11-11 28-11t28 11q11 11 11 28t-11 28L508-372q-6 6-13 8.5t-15 2.5Z"/></svg>');
    background: var(--clear-icon) right center no-repeat transparent !important;
}

.ui.dropdown>.clear.icon:before, .ui.label>.close.icon:before, .ui.label>.delete.icon:before {
    display: block;
    height: 20px;
    width: 20px;
    margin: 0;
    content: '';
    --clear-icon:url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="M480-424 284-228q-11 11-28 11t-28-11q-11-11-11-28t11-28l196-196-196-196q-11-11-11-28t11-28q11-11 28-11t28 11l196 196 196-196q11-11 28-11t28 11q11 11 11 28t-11 28L536-480l196 196q11 11 11 28t-11 28q-11 11-28 11t-28-11L480-424Z" /></svg>');
    background: var(--clear-icon) right center no-repeat transparent !important;
}

.ui.label>.close.icon:before, .ui.label>.delete.icon:before {
    height: 18px;
    width: 16px;
}

.Toastify {
    --toastify-color-info: var(--grey-400);
    --toastify-color-success: var(--green-400);
    --toastify-color-warning: var(--tertiary-400);
    --toastify-color-error: var(--red-400);
    --toastify-icon-color-info: var(--toastify-color-info);
    --toastify-icon-color-success: var(--toastify-color-success);
    --toastify-icon-color-warning: var(--toastify-color-warning);
    --toastify-icon-color-error: var(--toastify-color-error);
    --toastify-color-progress-info: var(--toastify-color-info);
    --toastify-color-progress-success: var(--toastify-color-success);
    --toastify-color-progress-warning: var(--toastify-color-warning);
    --toastify-color-progress-error: var(--toastify-color-error);
    --toastify-font-family: var(--text-primary);
    font-weight: 600;
}

.Toastify__toast-container--bottom-center {
    width: min(400px, 100%);
}

.Toastify__toast-theme--light {
    color: var(--grey-600);
    border: 1px solid var(--grey-300);
    box-shadow: 0px 4px 8px #484b4d1c;
}

.Toastify__toast--success {
  border-color: var(--green-200);
}

.Toastify__toast--error {
  border-color: var(--red-200);
}

.Toastify__toast--warning {
  border-color: var(--tertiary-200);
}

.Toastify__close-button {
    color: var(--grey-400);
    opacity: 1;
}

.Toastify__close-button:hover, .Toastify__close-button:focus {
    color: var(--grey-500);
}

.ui.dimmer {
  background-color: var(--dimmer-color);
}


@media (max-width: 1000px) {
  .ls-hide-l {
    display: none !important;
  }
}

@media (max-width: 800px) {
  .ls-hide-m {
    display: none !important;
  }
}

@media (max-width: 700px) {
  .ls-hide-s {
    display: none !important;
  }
}

@media (min-width: 700px) {
  .ls-show-s {
    display: none !important;
  }
}

@media (min-width: 1100px) {
  .ls-show-xl {
    display: none !important;
  }
}


@media only screen and (max-width: 480px) {
    .Toastify__toast {
        --toastify-toast-width: calc(100% - 48px);
        margin: 0 auto 88px;
    }
}